<nav class="container-fluid">
    <ul class="navbar p-5" >
      <li [routerLink]="['/home']" routerLinkActive="router-link-active" >ANASAYFA</li>
      <li [routerLink]="['/about']" routerLinkActive="router-link-active">HAKKIMIZDA</li>
      <li [routerLink]="['/home']">
        <div class="text-center">
        <img src="../../../assets/logo/logo.png" alt="">
      </div>
      </li>
      <li [routerLink]="['/products']" routerLinkActive="router-link-active">ÜRÜNLERİMİZ</li>
      <li [routerLink]="['/contact']" routerLinkActive="router-link-active" >İLETİŞİM</li>
    </ul>
</nav>