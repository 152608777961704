<div class="container-fluid p-0 background desktop">
  <div class="container-fluid bg-white p-0">
    <section id="section1" class="background-1">
      <app-header></app-header>
      <div class="section1_title-container ">
        <h3 class="section1_title d-flex align-text-bottom fade-in">
          İletişim
      </h3> 
      </div>
  </section>


    <div class="container70">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 fade-in">
          <img class="img" src="../../../assets/images/map03.png" alt="" width="85%">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 slide-right">
          <h3 class="contact-title ">
            Kızılgüney Zeytincilik Gıda Mad. Tar. Ürn.
            Hayv. San. Tic. Ltd. Şti.
          </h3>
          <h5 class="address ">
            <b>Adres:</b> <br>
            Kızılgüney Mah. Çine <br> Aydın, TURKEY
          </h5>
          <h6 class="phone ">
            <b>Telefon:</b><br>
            <a href="tel:5551234567">(555)123-4567</a></h6>
        </div>
      </div>
    </div>
  </div>
  <div class="background">
    
    <div class="form">
      <form>
        <div class="form-group">
          <label for="exampleFormControlInput1">Ad Soyad</label>
          <input
            type="text"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="İsim Soyisim"
          />
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput1">Email adresi</label>
          <input
            type="email"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="name@example.com"
          />
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput1">Telefon Numarası</label>
          <input
            type="phone"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="05********"
          />
        </div>

        <div class="form-group">
          <label for="exampleFormControlTextarea1">Mesajınız</label>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
          ></textarea>
        </div>
      </form>
      <div class="submitBtn">
        <button>Gönder</button>
      </div>
    </div>
</div>
  <section id="footer_mobile" style="background-color: white;">
    <app-footer></app-footer>
</section>
</div>

<div class="container-fluid p-0 background mobile">
  <div class="container-fluid bg-white p-0">
    <div
      class="container-fluid pl-4 pr-4 pt-3 pb-3 shadow-lg"
      style="background-color: rgba(255, 255, 255, 0.747)"
    >
      <div class="row">
        <div class="col">
          <img src="../../../assets/logo/logo_yatay.png" alt="" width="150" />
        </div>
        <div class="col text-right pt-3">
          <i
            class="fas fa-bars"
            style="font-size: 2em; color: #40100e"
            (click)="toggleMenu()"
          ></i>
        </div>
      </div>
    </div>
    <div
      *ngIf="menu == true"
      style="position: absolute; top: 5em; right: 0; width: 100%"
    >
      <div class="menu">
        <ul style="list-style: none">
          <li [routerLink]="['/home']" routerLinkActive="router-link-active">
            <a
              >Anasayfa</a
            >
          </li>
          <li [routerLink]="['/about']" routerLinkActive="router-link-active">
            <a
              >Hakkımızda</a
            >
          </li>
          <li
              [routerLink]="['/products']"
              routerLinkActive="router-link-active">
            <a
              >Ürünlerimiz</a
            >
          </li>
          <li [routerLink]="['/contact']" routerLinkActive="router-link-active">
            <a
              >İletişim</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="container-fluid">
      <div class="container70">
        <div class="row">
      
            <h3 class="contact-title">
              KIZILGÜNEY ZEYTİNCİLİK GIDA MAD. TAR. ÜRN.
              HAYV. SAN. TİC. LTD. ŞTİ.
            </h3>
            <h5 class="address">
              <b>Adres:</b> <br>
              Kızılgüney Mah. Çine <br> Aydın, TURKEY
            </h5>
            <h6 class="phone">
              <b>Telefon:</b><br>
              <a href="tel:5551234567">(555)123-4567</a></h6>
              
          </div>
        
      </div>
  </div>
  <div class="background">
    <div class="form">
      <form>
        <div class="form-group ">
          <label for="exampleFormControlInput1">Ad Soyad</label>
          <input
            type="text"
            class="form-control "
            id="exampleFormControlInput1"
            placeholder="İsim Soyisim"
          />
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput1">Email adresi</label>
          <input
            type="email"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="name@example.com"
          />
        </div>
        <div class="form-group">
          <label for="exampleFormControlInput1">Telefon Numarası</label>
          <input
            type="phone"
            class="form-control"
            id="exampleFormControlInput1"
            placeholder="05********"
          />
        </div>

        <div class="form-group">
          <label for="exampleFormControlTextarea1">Mesajınız</label>
          <textarea
            class="form-control"
            id="exampleFormControlTextarea1"
            rows="3"
          ></textarea>
        </div>
      </form>
      <div class="submitBtn">
        <button>Gönder</button>
      </div>
</div>
  </div>
  <section id="footer_mobile" style="background-color: white;">
    <app-footer></app-footer>
</section>
</div>
