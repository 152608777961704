<div class="container-fluid px-0 desktop">
    <div class="container-fluid bg-white px-0">
      <section id="section1" class="background-1">
        <app-header></app-header>
        <div class="section1_title-container ">
          <h3 class="section1_title fade-in d-flex align-text-bottom fade-in">
            Ürünlerimiz
        </h3> 
        </div>
    </section>
            <div class="row"style="padding-left: 10em; padding-right: 10em;">
                <div class="col-8 d-flex flex-column justify-content-center slide-right">
                    <p style="font-size: large;">
                        Bölgede  tek zeytin türü olarak memecik hakim .
                    <br><br>
                        Kayalık ve dağlık arazide sulama ve gübreleme İlaçlama yapılmamakta.
                        <br><br>
                        Yükşek polifenol değerine sahip memecik zeytin türü bölgede yüzyıllardır iklime ve toprağa adaptasyonu 
                        <br>
                        sağlamış ağaçlar olmasından en yüksek polifenolü üretebilecek kapasiteye sahiptir.
                        <br>
                        <br>
                        <span >
                        Ürünlerimiz hakkında 
                          ayrıntılı bilgi için 
                          bizimle <a [routerLink]="['/contact']">iletişime geçin.</a>
                        </span>
                        </p>
                </div>
                <div class="col-4 text-center">
                    <img class="slide-left" src="../../../assets/images/three_bottles.png" alt="" width="250px">
                    
                </div>
            </div>
    
        <div class="background">
        </div>
        <section id="footer_mobile" >
          <app-footer></app-footer>
      </section>
    </div>
</div>

<div class="container-fluid p-0 background mobile">
    <div class="container-fluid bg-white p-0">
      <div
        class="container-fluid pl-4 pr-4 pt-3 pb-3 shadow-lg"
        style="background-color: rgba(255, 255, 255, 0.747)"
      >
        <div class="row">
          <div class="col">
            <img src="../../../assets/logo/logo_yatay.png" alt="" width="150" />
          </div>
          <div class="col text-right pt-3">
            <i
              class="fas fa-bars"
              style="font-size: 2em; color: #40100e"
              (click)="toggleMenu()"
            ></i>
          </div>
        </div>
      </div>
      <div
        *ngIf="menu == true"
        style="position: absolute; top: 5em; right: 0; width: 100%"
      >
        <div class="menu">
          <ul style="list-style: none">
            <li [routerLink]="['/home']" routerLinkActive="router-link-active">
              <a
                >Anasayfa</a
              >
            </li>
            <li [routerLink]="['/about']" routerLinkActive="router-link-active">
              <a
                >Hakkımızda</a
              >
            </li>
            <li
                [routerLink]="['/products']"
                routerLinkActive="router-link-active">
              <a
                >Ürünlerimiz</a
              >
            </li>
            <li [routerLink]="['/contact']" routerLinkActive="router-link-active">
              <a 
                >İletişim</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="container-fluid p-3 text-center pt-4">
        <img src="../../../assets/images/three_bottles.png" alt="" width="250px">
        <p style="font-size: large;">
            Bölgede  tek zeytin türü olarak memecik hakim .
        <br><br>
            Kayalık ve dağlık arazide sulama ve gübreleme İlaçlama yapılmamakta.
            <br><br>
            Yüksek polifenol değerine sahip memecik zeytin türü bölgede yüzyıllardır iklime ve toprağa adaptasyonu 
                        sağlamış ağaçlar olmasından en yüksek polifenolü üretebilecek kapasiteye sahiptir.
            </p>
            <span >
              Ürünlerimiz hakkında 
                ayrıntılı bilgi için 
                bizimle <a [routerLink]="['/contact']">iletişime geçin.</a>
              </span>
  </div>
    </div>
    <div class="background"></div>

    <section id="footer_mobile" style="background-color: white;">
      <app-footer></app-footer>
  </section>
  </div>

