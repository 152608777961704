    <!-- DESKTOP -->




<div class="container-fluid p-0 desktop">
    <section id="section1" class="background-1">
        <app-header></app-header>
        <div class="section1_title-container" style="padding-top: 15em; padding-bottom: 5em;">

            
          
           <h4 class="section1_title fade-in">
            Zeytin ağaçları içinde, Üretici ile iç içe 
        </h4> 
        </div>
    </section>
    <section id="section2">
        <div class="row" style="padding-top: 5em; padding-bottom: 5em;">
            <div class="col">
                <img class="slide-right" src="../../../assets/images/Slide1.png" alt="" width="60%">
            </div>
            <div class="col">
                <h3 class="section2_title fade-in">
                    Bahçeden Sofraya
                </h3>
                <p class="section2_p fade-in">
                    Zeytinyağlarımız 
                    Aydın'ın 
                    Kızıl Güney Köyü'ndeki
                    Bahcelerimizde
                    İyi tarım uygulamları
                    ile yetiştirilen 
                    zeytinlerimizden
                    üretilir.
                </p>
            </div>
        </div>
    </section>
    <section id="section3" >
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    <h3 class="section3_title fade-in">Ürünlerimiz</h3>
                    <p class="section3_p fade-in">
                        KızılGüney,
                    Organik Zeytinlerden 
                    Sağlıklı ve <br> 
                    Kaliteli Zeytinyağları üretir.
                    </p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                    <img class="products_img slide-left" src="../../../assets/images/three_bottles.png" alt="">
                </div>
            </div>
            <div class="button-wrapper">
                <button class="section3_button fade-in" [routerLink]="['/products']" routerLinkActive="router-link-active">
                    Daha fazla
                </button>
            </div>
        </div>

        <!-- <div class="container-fluid py-5 my-5">
           
        <div class="row">
             <div class="container">
                 <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 py-4 m-0">
               
                <h3 class="section3_title slide-right">
                    Ürünlerimiz
                </h3>
                <p class="section3_p slide-right">
                    KızılGüney, <br>
                    Organik Zeytinlerden 
                    Sağlıklı ve <br> 
                    Kaliteli Zeytinyağları üretir.
                </p>
            
            </div>
            <div class="col-xl-6  col-lg-6 col-md-6 p-0 m-0">
                <img class="products_img slide-left" src="../../../assets/images/three_bottles.png" alt="" width="65%">
            </div>

        </div>
        
       </div>
    </div>
       <div class="row">
          <div class="container p-0">
            <button class="section3_button" [routerLink]="['/products']" routerLinkActive="router-link-active">
                Daha fazla
            </button>
        </div>
        
       </div>
    </div>  -->

        <!-- <div class="row">
            <div class="col">
                <h3 class="section3_title">
                    Ürünlerimiz
                </h3>
                <p class="section3_p">
                    KızılGüney, <br>
Organik Zeytinlerden 
Sağlıklı ve <br> 
Kaliteli Zeytinyağları üretir.
                </p>
                <button class="section3_button" [routerLink]="['/products']" routerLinkActive="router-link-active">
                    Daha fazla
                </button>
            </div>
            <div class="col">
                <img src="../../../assets/images/three_bottles.png" alt="" width="50%">
            </div>
        </div> -->
    </section>
    <section id="section4" >
        <div class="row">
            <div class="col">
                <img src="../../../assets/images/oilandolive.png" width="60%" alt="">
            </div>
            <div class="col">
                <h3 class="section4_title">
                    Hikayemiz
                </h3>
                <p class="section4_p">
                    Organik tarım projemiz ile bölge üreticisiyle işbirliği içinde
                    organik üretim yapmaktayız. 
                    Bölgede zeytincilik yapan köylere organik tarım projemiz ile 
                    kalkınmasına destek olmakta 
                    aynı zamanda katmadeğer yaratmaktayız. 
                </p>
                <div class="section4_btn_container">
                    <button class="section4_button" [routerLink]="['/about']" routerLinkActive="router-link-active" >Daha fazla</button>
                </div>
            </div>
        </div>
    </section>
    <section id="section5" class="section5_background ">
        <h5 class="section5_text">
            Çine Madran Dağı eteklerinde 
            bölge 300 rakımlı memecik türü zeytinler orman vasfında  
            150-200 yaş  ortalamasına sahip ağaç varlığı ile
            ekolojik olarak iklime ve coğrafyaya adapte olmuş,
            altında hayvanların otladığı yabani hayatın devam ettiği
            endüstriyel tarımın yapılmadığı bir coğrafyada faaliyet göstermekteyiz. 
        </h5>
    </section>
    <section id="footer">
        <app-footer></app-footer>
    </section>    
</div>
    <!-- TABLET -->
    <div class="container-fluid tablet p-0">
    <section id="section1_tablet" class="background-1_tablet tablet">
        <app-header></app-header>
        <div class="section1_title-container" style="padding-top: 5em; padding-bottom: 15em;">
            <h3 class="section1_title">
             KADRİ VE ULAŞ ÇALIŞIYOR
         </h3>
         </div>
    </section>
    <section id="section2_tablet" >
        <div class="row">
            <div class="col">
                <div class="img-wrapper">
                    <img src="../../../assets/images/Slide1.png" alt="" width="60%">
                </div>
            </div>
            <div class="col">
                <h3 class="section2_title">
                    Bahce'den Sofraya
                </h3>
                <p class="section2_p">
                    Zeytinyağlarımız 
                    Aydın'ın 
                    Kızıl Güney Köyü'ndeki
                    Bahcelerimizde
                    İyi tarım uygulamları
                    ile yetiştirilen 
                    zeytinlerimizden
                    üretilir.
                </p>
            </div>
        </div>
    </section>
    
        <section id="section3_tablet" >
            <div class="container-fluid">
               
            <div class="row">
                 <div class="container">
                     <div class="row">
                <div class="col-lg-6 col-md-6 py-4 m-0">
                   
                    <h3 class="section3_title">
                        Ürünlerimiz
                    </h3>
                    <p class="section3_p">
                        KızılGüney, <br>
                        Organik Zeytinlerden 
                        Sağlıklı ve <br> 
                        Kaliteli Zeytinyağları üretir.
                    </p>
                
                </div>
                <div class="col-lg-6 col-md-6 p-0 m-0">
                    <img class="products_img" src="../../../assets/images/three_bottles.png" alt="" width="45%">
                </div>
    
            </div>
            
           </div>
        </div>
           <div class="row">
              <div class="container p-0">
                <button class="section3_button" [routerLink]="['/products']" routerLinkActive="router-link-active">
                    Daha fazla
                </button>
            </div>
            
           </div>
        </div> 
    
            <!-- <div class="row">
                <div class="col">
                    <h3 class="section3_title">
                        Ürünlerimiz
                    </h3>
                    <p class="section3_p">
                        KızılGüney, <br>
    Organik Zeytinlerden 
    Sağlıklı ve <br> 
    Kaliteli Zeytinyağları üretir.
                    </p>
                    <button class="section3_button" [routerLink]="['/products']" routerLinkActive="router-link-active">
                        Daha fazla
                    </button>
                </div>
                <div class="col">
                    <img src="../../../assets/images/three_bottles.png" alt="" width="50%">
                </div>
            </div> -->
        </section>
    
    <section id="section4_tablet">
        <div class="row">
            <div class="col">
                <img src="../../../assets/images/oilandolive.png" width="80%" alt="">
            </div>
            <div class="col">
                <h3 class="section4_title">
                    Hikayemiz
                </h3>
                <p class="section4_p">
                    Organik tarım projemiz ile bölge üreticisiyle işbirliği içinde
                    organik üretim yapmaktayız. 
                    Bölgede zeytincilik yapan köylere organik tarım projemiz ile 
                    kalkınmasına destek olmakta 
                    aynı zamanda katmadeğer yaratmaktayız. 
                </p>
                <div class="section4_btn_container">
                    <button class="section4_button" [routerLink]="['/about']" routerLinkActive="router-link-active" >Daha fazla</button>
                </div>
            </div>
        </div>
    </section>
    <section id="section5_tablet">
        <h5 class="section5_text">
            Çine Madran Dağı eteklerinde 
            bölge 300 rakımlı memecik türü zeytinler orman vasfında  
            150-200 yaş  ortalamasına sahip ağaç varlığı ile
            ekolojik olarak iklime ve coğrafyaya adapte olmuş,
            altında hayvanların otladığı yabani hayatın devam ettiği
            endüstriyel tarımın yapılmadığı bir coğrafyada faaliyet göstermekteyiz. 
        </h5>
    </section>
    <section id="footer_tablet">
        <app-footer></app-footer>
    </section>
</div>


<!--MOBILE PHONE-->

<div class="container-fluid p-0 mobile">
     <div class="container-fluid pl-4 pr-4 pt-3 pb-3 shadow-lg" style="background-color: rgba(255, 255, 255, 0.747);">        
          <div class="row">
              <div class="col">
                  <img src="../../../assets/logo/logo_yatay.png" alt="" width="150">
              </div>
              <div class="col text-right pt-3">
                <i class="fas fa-bars" style="font-size: 2em; color: #40100E;" (click)="toggleMenu()" ></i>
              </div>
          </div>
        </div>
    <section id="section1_mobile" class="background-1_mobile" >
        <div *ngIf="menu == true" style="position:absolute; top: 5em; right: 0; width: 100%;">
            <div class=" menu">
                <ul style="list-style: none;">
                    <li [routerLink]="['/home']" routerLinkActive="router-link-active"><a >Anasayfa</a></li>
                    <li [routerLink]="['/about']" routerLinkActive="router-link-active"><a >Hakkımızda</a></li>
                    <li [routerLink]="['/products']" routerLinkActive="router-link-active"><a >Ürünlerimiz</a></li>
                    <li [routerLink]="['/contact']" routerLinkActive="router-link-active" ><a>İletişim</a></li>
                </ul>
            </div>
        </div>

        <h3 class="section1_title">
            Bahceden Sofraya
        </h3>
        <p class="section1_p">
            Zeytinyağlarımız 
Aydın'ın 
Kızıl Güney Köyü'ndeki
Bahcelerimizde
İyi tarım uygulamları
ile yetiştirilen 
zeytinlerimizden
üretilir.
        </p>

    </section>
   
    <section id="section2_mobile">
        <h3 class="section2_title">
            Hikayemiz
        </h3>
        <div class="row">
            <div class="col-8">
               
                <p class="section2_p">
                    Organik tarım projemiz ile 
bölge üreticisiyle işbirliği 
içinde
organik üretim yapmaktayız. 
Bölgede zeytincilik yapan 
köylere organik tarım 
projemiz ile 
kalkınmasına destek olmakta 
aynı zamanda katmadeğer 
yaratmaktayız . 
                </p>
            </div>
            <div class="col-4">
                <img src="../../../assets/images/bird_vector.png" alt="" width="80%">
            </div>
            <div class="moreButton_story">
                <button class="more" [routerLink]="['/about']" routerLinkActive="router-link-active" >
                    Daha Fazla
                </button>
            </div>
        </div>
       
    </section>

    <section id="section3_mobile">
        <div class="border">
            <h3 class="section3_title">
            Urunlerimiz
        </h3>
        <div class="img">
            <img src="../../../assets/images/three_bottles.png" alt="" width="150px">
        </div>
        <div class="moreButton_products">
            <button class="more" [routerLink]="['/products']" routerLinkActive="router-link-active" >
                Daha Fazla
            </button>
        </div>
        </div>
    </section>
    <section id="section4_mobile">
        <p class="section4_p">
            Aydın Çine madran dağı 
eteklerinde 
Bölge 300 rakımlı memecik 
türü zeytinler orman vasfında  
150 200 yaş  ortalamasına 
sahip ağaç varlığı ile
ekolojik olarak iklime ve 
coğrafyaya adapte olmuş,
altında hayvanların otladığı 
yabani hayatın devam ettiği
endüstriyel tarımın yapılmadığı 
bir coğrafyada faaliyet 
göstermekteyiz 
        </p>
    </section>
    <section id="footer_mobile" >
        <app-footer></app-footer>
    </section>
</div>
