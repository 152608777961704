<div class="container-fluid p-0 background desktop">
  <div class="container-fluid bg-white p-0">
    <section id="section1" class="background-1">
      <app-header></app-header>
      <div class="section1_title-container">
        <h3 class="section1_title fade-in d-flex align-text-bottom">
          Hakkımızda
      </h3> 
      </div>
  </section>

    <div class="row ">
      <div class="col-7">
        <p class="fade-in">
          2005 yılında kurulan işletme, aile şirketi olarak devam etmekte.<br />
          <b> Ortakları Erol ve Melih Dalgar</b> <br /><br />
          Aydın Çine Madran Dağı eteklerinde, bölge 300 rakımlı, memecik türü
          zeytinler orman vasfında. 150-200 yaş ortalamasına sahip ağaç varlığı
          ile ekolojik olarak iklime ve coğrafyaya adapte olmuş, altında
          hayvanların otladığı yabani hayatın devam ettiği, endüstriyel tarımın
          yapılmadığı bir coğrafyada faaliyet göstermekteyiz.
          <br />
          <br />
          Organik tarım projemiz ile bölge üreticisiyle işbirliği içinde organik
          üretim yapmaktayız. Bölgede zeytincilik yapan köylere organik tarım
          projemiz ile kalkınmasına destek olmakta aynı zamanda katmadeğer
          yaratmaktayız.
          <br />
          <br />
          Zeytin ağacının yetişmesi ve meyve vermesi uzun ve zorlu bir süreçten
          sonra olmakta . Zeytin meyvesinden beslenen kuşlar ve yabani canlılar
          çekirdeğinin sert yapısını zayıflatarak sindirimi gerçekleştirirler.
          Zayıflamış çekirdek yağmurlarla üzerine toprak örtülmesi ile dikilmiş
          olur Bu haliyle delice tabir edilen yabani zeytindir 20 30
          yaşlarındaki delice ağaçları aşılanmak suretiyle meyve veren ağaca
          dönüşmüş olur.
          <br />
          <br />
          Biz buna bölgemizdeki zeytini kurtlar kuşlar diker diyoruz
        </p>
      </div>
      <div class="col-5 text-center">
        <img
          src="../../../assets/images/bird_vector.png"
          alt=""
          width="50%"
          class="slide-left bird-img"
        />
      </div>
    </div>
    <div class="container-fluid p-5">
      <section id="references">
        <h3 class="title">Sertifikalarımız</h3>
        <ul class="d-flex flex-columns justify-content-around pt-5 flex-wrap">
          <li class="text-center">
            <a  target="_blank" href="../../../assets/serifikalar/cert.jpg" class="py-4">
           <img src="../../../assets/serifikalar/cert.jpg" alt="" width="250px" class="pb-3">
            (EC) 834/2007 and (EC) 889/2008
            </a>
          </li>
          <li class="text-center">
            <a target="_blank" href="../../../assets/serifikalar/JAS.jpg" class="py-4">
            <img src="../../../assets/serifikalar/JAS.jpg" alt="" width="250px" class="pb-3">
              JAS Sertifikası
              </a>
          </li>
          <li class="text-center">
            <a target="_blank" href="../../../assets/serifikalar/mutesebbis.jpg" class="py-4">
              <img src="../../../assets/serifikalar/mutesebbis.jpg" alt="" width="250px" class="pb-3">
              Müteşebbis Sertifikası
            </a>
          </li>
          <li class="text-center">
            <a target="_blank" href="../../../assets/serifikalar/NOP.jpg" class="py-4">
              <img src="../../../assets/serifikalar/NOP.jpg" alt="" width="250px" class="pb-3">
              NOP Sertifikası
              </a>
          </li>
        </ul>
      </section>
    </div>
  </div>
  <div class="background">
    
  </div>
  <section id="footer_mobile" style="background-color: white;">
    <app-footer></app-footer>
</section>
</div>

<div class="container-fluid p-0 background mobile">
  <div class="container-fluid bg-white p-0 ">
    <div
      class="container-fluid pl-4 pr-4 pt-3 pb-3 shadow-lg"
      style="background-color: rgba(255, 255, 255, 0.747)"
    >
      <div class="row">
        <div class="col">
          <img src="../../../assets/logo/logo_yatay.png" alt="" width="150" />
        </div>
        <div class="col text-right pt-3">
          <i
            class="fas fa-bars"
            style="font-size: 2em; color: #40100e"
            (click)="toggleMenu()"
          ></i>
        </div>
      </div>
    </div>
    <div
      *ngIf="menu == true"
      style="position: absolute; top: 5em; right: 0; width: 100%"
    >
      <div class="menu">
        <ul style="list-style: none">
          <li [routerLink]="['/home']" routerLinkActive="router-link-active">
            <a
              >Anasayfa</a
            >
          </li>
          <li [routerLink]="['/about']" routerLinkActive="router-link-active">
            <a 
              >Hakkımızda</a
            >
          </li>
          <li
              [routerLink]="['/products']"
              routerLinkActive="router-link-active">
            <a
              >Ürünlerimiz</a
            >
          </li>
          <li [routerLink]="['/contact']" routerLinkActive="router-link-active">
            <a
              >İletişim</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="container-fluid p-3 text-center PT-4">
    <img src="../../../assets/images/bird_vector.png" alt="" width="40%" />

    <p class="mt-4">
      2005 yılında kurulan işletme, aile şirketi olarak devam etmekte.<br />
      <b> Ortakları Erol ve Melih Dalgar</b> <br /><br />
      Aydın Çine Madran Dağı eteklerinde, bölge 300 rakımlı, memecik türü
      zeytinler orman vasfında. 150-200 yaş ortalamasına sahip ağaç varlığı ile
      ekolojik olarak iklime ve coğrafyaya adapte olmuş, altında hayvanların
      otladığı yabani hayatın devam ettiği, endüstriyel tarımın yapılmadığı bir
      coğrafyada faaliyet göstermekteyiz.
      <br />
      <br />
      Organik tarım projemiz ile bölge üreticisiyle işbirliği içinde organik
      üretim yapmaktayız. Bölgede zeytincilik yapan köylere organik tarım
      projemiz ile kalkınmasına destek olmakta aynı zamanda katmadeğer
      yaratmaktayız.
      <br />
      <br />
      Zeytin ağacının yetişmesi ve meyve vermesi uzun ve zorlu bir süreçten
      sonra olmakta . Zeytin meyvesinden beslenen kuşlar ve yabani canlılar
      çekirdeğinin sert yapısını zayıflatarak sindirimi gerçekleştirirler.
      Zayıflamış çekirdek yağmurlarla üzerine toprak örtülmesi ile dikilmiş olur
      Bu haliyle delice tabir edilen yabani zeytindir 20 30 yaşlarındaki delice
      ağaçları aşılanmak suretiyle meyve veren ağaca dönüşmüş olur.
      <br />
      <br />
      Biz buna bölgemizdeki zeytini kurtlar kuşlar diker diyoruz
    </p>
</div>
  </div>
  <div class="background">
    <div class="container-fluid p-3">
      <section id="references" >
        <h3 class="title">Sertifikalarımız</h3>
        <ul class=" pl-0 ">
          <li class=" ">
            <a href="" class="py-4">
              <div class="img-container">
           <img src="../../../assets/serifikalar/cert.jpg" alt="" width="60%" class="pb-3">
          </div> 
           (EC) 834/2007 and (EC) 889/2008
            </a>
          </li>
          <li class="">
            <a href="" class="py-4">
              <div class="img-container">
            <img src="../../../assets/serifikalar/JAS.jpg" alt="" width="60%" class="pb-3">
          </div>  
            JAS Sertifikası
              </a>
          </li>
          <li class="">
            <a href="" class="py-4">
              <div class="img-container">
              <img src="../../../assets/serifikalar/mutesebbis.jpg" alt="" width="60%" class="pb-3">
            </div>
              Müteşebbis Sertifikası
            </a>
          </li>
          <li class="">
            <a href="" class="py-4">
              <div class="img-container">
              <img src="../../../assets/serifikalar/NOP.jpg" alt="" width="60%" class="pb-3">
              
            </div>NOP Sertifikası
              </a>
          </li>
        </ul>
      </section>
    </div>
  </div>
  <section id="footer_mobile" style="background-color: white;">
    <app-footer></app-footer>
</section>
</div>
