<div class="container-fluid padding-footer p-2 px-5 desktop" >
  <div class="row">
    <div class="col-lg-3 col-md-3 col-sm-12">
      <img src="../../../assets/logo/logo_yatay.png" alt="" class="logo" />
    </div>
    <div class="col-lg-6 col-md-6 d-flex align-items-center justify-content-around">
      <p>©️ 2021 Kızılgüney Zeyincilik. Tüm Hakları Saklıdır.</p>
      <p>Tasarım: <b><a href="https://www.dukateknoloji.com">Duka Teknoloji</a></b></p>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-12 d-flex align-items-center justify-content-end">
      <ul>
        <li><img src="../../../assets/logo/social-media/facebook.png" alt="" width="80%"></li>
        <li><img src="../../../assets/logo/social-media/instagram.png" alt="" width="80%"></li>
        <li><img src="../../../assets/logo/social-media/youtube.png" alt="" width="80%"></li>
        <li><img src="../../../assets/logo/social-media/whatsapp.png" alt="" width="80%"></li>
      </ul>
    </div>
  </div>
</div>

<div class="container-fluid mobile-footer py-2">
  <div class="row d-flex justify-content-center align-center pl-0 m-0">
    KızılGüney
  </div>
 
</div>